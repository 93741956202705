@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply w-full p-4 rounded-xl text-black1 font-roboto text-sm;
  }

  .error-input {
    @apply ring-1 ring-red1 text-red1 placeholder-current;
  }
  .focus-input {
    @apply focus:ring focus:ring-blue-300 focus:border-blue-200;
  }

  .btn-login {
    @apply bg-yellow1 py-4 w-full rounded-10 font-inter font-bold text-blue1 focus:outline-none focus:ring focus:ring-yellow2 focus:border-yellow2;
  }

  .login-background-img {
    background-image: url('./assets/illustration/il-login-circle.svg'),
      url('./assets/illustration/ic-cirle-full.svg'),
      url('./assets/illustration/ic-circle-half.svg');
    background-attachment: scroll, fixed, fixed;
    background-position: top right, 75% 95%, 72% 100%;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

  .side-menu {
    @apply text-black3;
  }

  .active {
    @apply text-blue1;
  }

  .outline input:focus-within ~ label,
  .outline input:not(:placeholder-shown) ~ label {
    @apply transform -translate-y-3 z-0 ml-3 px-1 py-0;
  }

  .option-active {
    @apply bg-blue1 text-white;
  }
}

@layer utilities {
  @variants responsive {
    .scrollbar-none::-webkit-scrollbar {
      display: none;
    }

    /* .side-bar: */
    /* width */
    .side-bar::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .side-bar::-webkit-scrollbar-track {
      background: #cce7ff;
    }

    /* Handle */
    .side-bar::-webkit-scrollbar-thumb {
      background: #d6d6d6;
      border-radius: 10px;
    }

    /* Handle on hover */
    .side-bar::-webkit-scrollbar-thumb:hover {
      background: #bababa;
    }

    .tes-blur {
      backdrop-filter: blur(5px);
    }

    /* Toggle A */
    input#toogleA:checked ~ .dot {
      transform: translateX(100%);
      background-color: #0085ff;
    }
  }
}
